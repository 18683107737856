const numberOfMovies = [
    {
        identifier: "one",
        value: 1
    },{
        identifier: "five",
        value: 5
    },{
        identifier: "ten",
        value: 10
    },
]


export default numberOfMovies;